/**
 *  Set draft spaces
 */
.m0  { margin: 0; }

// Margin Top
.dm-top-1 {
    margin-top: .5rem;
}
.dm-top-2 {
    margin-top: 1.5rem;
}
.dm-top-3 {
    margin-top: 3rem;
}

// Padding Top
.dp-top-1 {
    padding-top: .5rem;
}
.dp-top-2 {
    padding-top: 1.5rem;
}
.dp-top-3 {
    padding-top: 3rem;
}

// Margin right
.dm-right-1 {
    margin-right: .5rem;
}
.dm-right-2 {
    margin-right: 1.5rem;
}
.dm-right-3 {
    margin-right: 3rem;
}

// Padding right
.dp-right-1 {
    padding-right: .5rem;
}
.dp-right-2 {
    padding-right: 1.5rem;
}
.dp-right-3 {
    padding-right: 3rem;
}

// Margin bottom
.dm-bottom-1 {
    margin-bottom: .5rem;
}
.dm-bottom-2 {
    margin-bottom: 1.5rem;
}
.dm-bottom-3 {
    margin-bottom: 3rem;
}

// Padding bottom
.dp-bottom-1 {
    padding-bottom: .5rem;
}
.dp-bottom-2 {
    padding-bottom: 1.5rem;
}
.dp-bottom-3 {
    padding-bottom: 3rem;
}

// Margin left
.dm-left-1 {
    margin-left: .5rem;
}
.dm-left-2 {
    margin-left: 1.5rem;
}
.dm-left-3 {
    margin-left: 3rem;
}

// Padding left
.dp-left-1 {
    padding-left: .5rem;
}
.dp-left-2 {
    padding-left: 1.5rem;
}
.dp-left-3 {
    padding-left: 3rem;
}

@media screen and (min-width: 48em) {
    // Margin Top
    .dm-top-1 {
        margin-top: 1.5rem;
    }
    .dm-top-2 {
        margin-top: 2.75rem;
    }
    .dm-top-3 {
        margin-top: 4rem;
    }

    // Padding Top
    .dp-top-1 {
        padding-top: 1.5rem;
    }
    .dp-top-2 {
        padding-top: 2.75rem;
    }
    .dp-top-3 {
        padding-top: 4rem;
    }

    // Margin right
    .dm-right-1 {
        margin-right: 1.5rem;
    }
    .dm-right-2 {
        margin-right: 2.75rem;
    }
    .dm-right-3 {
        margin-right: 4rem;
    }

    // Padding right
    .dp-right-1 {
        padding-right: 1.5rem;
    }
    .dp-right-2 {
        padding-right: 2.75rem;
    }
    .dp-right-3 {
        padding-right: 4rem;
    }

    // Margin bottom
    .dm-bottom-1 {
        margin-bottom: 1.5rem;
    }
    .dm-bottom-2 {
        margin-bottom: 2.75rem;
    }
    .dm-bottom-3 {
        margin-bottom: 4rem;
    }

    // Padding bottom
    .dp-bottom-1 {
        padding-bottom: 1.5rem;
    }
    .dp-bottom-2 {
        padding-bottom: 2.75rem;
    }
    .dp-bottom-3 {
        padding-bottom: 4rem;
    }

    // Margin left
    .dm-left-1 {
        margin-left: 1.5rem;
    }
    .dm-left-2 {
        margin-left: 2.75rem;
    }
    .dm-left-3 {
        margin-left: 4rem;
    }

    // Padding left
    .dp-left-1 {
        padding-left: 1.5rem;
    }
    .dp-left-2 {
        padding-left: 2.75rem;
    }
    .dp-left-3 {
        padding-left: 4rem;
    }
}

@media screen and (min-width: 80em) {
    // Margin Top
    .dm-top-1 {
        margin-top: 2.75rem;
    }
    .dm-top-2 {
        margin-top: 4rem;
    }
    .dm-top-3 {
        margin-top: 6rem;
    }

    // Padding Top
    .dp-top-1 {
        padding-top: 2.75rem;
    }
    .dp-top-2 {
        padding-top: 4rem;
    }
    .dp-top-3 {
        padding-top: 6rem;
    }

    // Margin right
    .dm-right-1 {
        margin-right: 2.75rem;
    }
    .dm-right-2 {
        margin-right: 4rem;
    }
    .dm-right-3 {
        margin-right: 6rem;
    }

    // Padding right
    .dp-right-1 {
        padding-right: 2.75rem;
    }
    .dp-right-2 {
        padding-right: 4rem;
    }
    .dp-right-3 {
        padding-right: 6rem;
    }

    // Margin bottom
    .dm-bottom-1 {
        margin-bottom: 2.75rem;
    }
    .dm-bottom-2 {
        margin-bottom: 4rem;
    }
    .dm-bottom-3 {
        margin-bottom: 6rem;
    }

    // Padding bottom
    .dp-bottom-1 {
        padding-bottom: 2.75rem;
    }
    .dp-bottom-2 {
        padding-bottom: 4rem;
    }
    .dp-bottom-3 {
        padding-bottom: 6rem;
    }

    // Margin left
    .dm-left-1 {
        margin-left: 2.75rem;
    }
    .dm-left-2 {
        margin-left: 4rem;
    }
    .dm-left-3 {
        margin-left: 6rem;
    }

    // Padding left
    .dp-left-1 {
        padding-left: 2.75rem;
    }
    .dp-left-2 {
        padding-left: 4rem;
    }
    .dp-left-3 {
        padding-left: 6rem;
    }
}


