h3,
h4,
h5,
h6 {
    hyphens: auto;
    -webkit-hyphens: auto;
    line-height: 1.25;
}

h1, h2, h3, h4 {    
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

h3 {
    font-size: 1.625rem;
    font-weight: 700;
    text-transform: uppercase;

    @media screen and (max-width: 62em) {
        font-size: 1.4rem;
    }   
    @media screen and (max-width: 48em) {
        font-size: 1.2rem;
    }           
}

h4,
h5 {
    font-size: 1.625rem;
    font-weight: 700;

    @media screen and (max-width: 62em) {
        font-size: 1.2rem;
    }   
    @media screen and (max-width: 48em) {
        font-size: 1.125rem;
    }           

}

h5 {
    font-weight: 300;
}

h6 {
    font-size: 1.25rem;
    font-weight: 700;
}

p,
ol,
ul {
    font-size: .875rem;
    margin: 0;

    + p,
    + ol,
    + ul {
        margin-top: 1.5rem;
    }
}

ul,
ol {
    padding-left: 1.25rem;
}

.button {
    transition: background .3s, border .3s, color .3s;

    &:hover,
    &:focus-visible {
        border-color: $color-primary;
        background: $color-primary;
        color: #fff;
    }

    &[disabled] {
        background: $color-gray-light;

        &:hover {
            background: $color-gray-light;
        }
    }
}

.button--primary-light {
    &:hover,
    &:focus-visible {
        border-color: $color-primary;
    }
}

main a:not(.button) {
    text-decoration: none;
    // border-bottom: 1px solid;
    color: $color-gray-darker;

    &:hover,
    &:focus {
        border-color: $color-primary;
        color: $color-gray-darker;
    }
}

@media screen and (min-width: 62em) {
    p,
    ol,
    ul {
        font-size: 1rem;
    }

    h2 {
        font-size: 1.8rem;
    }

    h3 {
        font-size: 1.6rem;
    }

    h4 {
        font-size: 1.4rem;
    }

    h5,
    h6 {
        font-size: 1.625rem;
    }
}