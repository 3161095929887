// If we need to wrap content with a container.
.container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem;

    // Containers inside containers do not create superflous padding! This is so important!
    .container {
        padding-right: 0;
        padding-left: 0;
    }
}

// Set this to the most common width value in the layout.
.container {
    max-width: 23.5rem; // 376px
}

.container-full-width {
    max-width: none;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
}

@media screen and (min-width: 40em) {
    .container {
        max-width: 40rem; // 640px
    }
}

@media screen and (min-width: 48em) {
    .container {
        max-width: 48rem; // 768px
    }
}

@media screen and (min-width: 64em) {
    .container {
        max-width: 64rem; // 1024px
    }
}

@media screen and (min-width: 80em) {
    .container {
        // max-width: 80rem; // 1280px
        max-width: 75rem; // 1200px
    }
}

// @media screen and (min-width: 90em) {
//     .container {
//         max-width: 90rem; //1440
//     }
// }

