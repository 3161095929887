.innung {

}
.innung__image {
    img {
        max-width: 200px;
    }
}
.innung__content {
    h4 {
        font-size: 1.4rem;
    }
}

// .teaser-element {
//     // padding: 5px;
//     border-radius: 20px;
//     background-color: aqua;
//     height: 100%;

//     h2 {
//         font-size: 1.4rem;
//         // margin-top: 10px;
//     }
// }

// .teaser-element__headline {
//     text-align: center;
//     color: #fff;
//     padding-top: 15px;
// }
// .teaser-element__image {

//     overflow: hidden;
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;

//     img {
//         width: 100%;
//     }
// }
// .teaser-element__content {
//     text-align: center;
//     color: #fff;
//     padding: 10px;
//     padding-top: 25px;
//     p {
//         font-size: 1.3rem;
//     }
//     height: 100%;
// }
// .teaser-element__spacer {
//     padding: 15px;
//     height: 100%;
// }
// .teaser-element__border {
//     border-radius: 20px;
//     border: 3px solid #ccc;
//     overflow: hidden;
//     height: calc(100% - 30px);
// }




// .element {
//     background-color: yellow;
// }

// $col_gap: 1rem;
// .flexbox {
//     display: flex;
  
//     .teaser-element__border {
//         height: 100%;
//     }

//     // Ensure content elements fill up the .column
//     .element {
//       height: 100%;
//     }
  
//     &.col-3 {
//       // Explicitly needs defined to wrap overflow items to the next virtual row
//       flex-wrap: wrap;
  
//       .column {
//         margin: $col_gap/2;
//         max-width: calc((100% / 3) - #{$col_gap});
//         padding: 10px;
//       }
//     }
//   }