.footer {
    // background-image: url(/bundles/app/img/footer_bg.png);
    // background-position: top right;
    // @media screen and (max-width: 62em) {
    //     background-size: cover;
    // } 
    // @media screen and (max-width: 48em) {
    //     background-image: none;
    //     background: $red-gradient;
    // }
    
    
    @media screen and (max-width: 48em) {
        .hide_mobile   {
            display: none;
        }
    } 
   
    color: #fff;
    h1, h2, h3, h4 {
        color: #fff;
        font-size: 2rem;
        
    }
    a {
        color: #fff;
        &:hover, &:link, &:active, &:visited {
            color: #fff;
        }
    }
    background-color: $orange;
    .container {
        position: relative;
        // @media screen and (min-width: 80em) {
        //     display: flex;
        //     justify-content: space-between;
        // }
        
        @media screen and (min-width: 48em) {
            display: grid;
            grid-gap: 1.5rem;
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (min-width: 62em) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (min-width: 75em) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (max-width: 90em) {
            padding-left: 3rem;
            padding-right: 3rem;
        }        

    }
    padding-top: 4rem;
    padding-bottom: 3rem;
    @media screen and (max-width: 48em) {
        padding-bottom: 1rem;
    }  
}
.footer__navigation {
    @media screen and (max-width: 48em) {
        margin-top: 2rem;
    }  
}

.footer__sponsor {
    text-align: right;
    // @media screen and (min-width: 80em) {
    //     width: 30%;
    // }
    // @media screen and (max-width: 80em) {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     max-width: 160px;
    //     margin-right: 15px;
    // }

    img {
        max-width: 200px;
    }

    @media screen and (max-width: 75em) {
        max-width: 160px;
        text-align: left;
    }    
    @media screen and (max-width: 48em) {
        margin-top: 2rem;
    }    
}

// 75em 1200px xl
// 62em 992px  lg
// 48em 768px  md
// 36em 576px  sm
