// Resize SVG Icons to match the text-size of an element.
.icon {
    // Place the icon on the text baseline.
    position: relative;
    top: .125em;

    // Prevent the icon from shrinking inside a flex container.
    flex-shrink: 0;

    // Scale the icon to match the font-size of the parent element.
    height: 1em;
    width: 1em;

    // Let the icon take whatever color the parent has.
    // fill: transparent; // special settings for this project
    // stroke: currentColor; // special settings for this project
    fill: currentColor;
    stroke: currentColor;
    
    /*
     If the icon is used on a link, which has a color transition,
     we can also use a transition on the fill value.
    */
   transition: fill .3s;
}

// Hide contents accessibly.
.visually-hidden {
    // Take the element out of the layout flow.
    position: absolute;

    // Set minimum height and width so contents will still be announced by assitive tech.
    height: 1px;
    width: 1px;

    // Prevent content from overflowing the 1px box.
    overflow: hidden;

    // Clip the 1px box to 0 pixels.
    clip: rect(0, 0, 0, 0);

    // Prevent screen readers from reading multiple words as one.
    white-space: nowrap;
}