/**
 * Prefixing variable names with the thing they are used for
 * makes for easier recognition later.
 *
 * e.g. $color- for color variables.
 */

$font-fallback: sans-serif;
$font-primary: 'Open Sans', arial, sans-serif;

$color-chrome-100: #94A0B0;
$color-chrome-50: #D0DBEA;
$color-chrome-20: #EDF2F9;

$color-gray-400: #323139;
$color-gray-300: #8A898F;
$color-gray-200: #B1B1B4;
$color-gray-100: #D8D8DA;
$color-gray-50: #EAECEF;

$orange: #ff6600;

$petrol1: #306974;
$petrol2: #2b6f7c;
$petrol3: #c1c1c1;

$purple1: #8d4287;
$purple2: #993d91;
$purple3: #bcbcbe;

$grey1: #3d3d3d;
$grey2: #474545;
$grey3: #848480;

$grey: #454543;

$color-primary: $orange; 
$color-secondary: $purple1; 
$color-tertiary: #6D5F54; 

$color-info-red: #ED0022;

$color-positive: #00961F;

$color-gray-darker: #323139;
$color-gray-dark: #8a898f;
$color-gray: #b1b1b4;
$color-gray-light: #d8d8da;
$color-gray-lighter: #eaecef;

$color-gray-darker-rgba: rgba(50, 49, 57, .95);
$color-gray-light-rgba: rgba(216, 216, 218, .95);
$color-gray-lighter-rgba: rgba(234, 236, 239, .95);

// Full Width Teaser Image Overlay Color
$color-overlay-rgba-start: rgba(112, 110, 129, .5);
$color-overlay-rgba-end: rgb(148, 160, 176, .5);

$color-gray-darkest: #1e1e1e;



$text-color: #483112;

$grey-light: #E3E6EB;
$orange1: darken($orange, 10);
$orange2: darken($orange, 20);
$orange3: lighten($orange, 10);
$orange4: lighten($orange, 20);

// $color-primary: #D21515;
// $color-secondary: $orange1;


$red-gradient: linear-gradient(135deg, rgba(156,28,22,1) 0%, rgba(210,21,21,1) 45%, rgba(224,47,85,1) 100%);





$petrol-gradient: linear-gradient(135deg, $petrol1 0%, $petrol2 45%, $petrol3 100%);
$purple-gradient: linear-gradient(135deg, $purple1 0%, $purple2 45%, $purple3 100%);
$grey-gradient: linear-gradient(135deg, $grey1 0%, $grey2 45%, $grey3 100%);


// $mobilemenu-gradient: linear-gradient(135deg, #5ab1bd 0%, #2a616a 45%, #133942 100%);
$mobilemenu-gradient: linear-gradient(135deg, $color-primary 0%, $color-gray-darkest 45%, $text-color 100%);
$mobilemenu-gradient: linear-gradient(135deg, $text-color 0%, $color-gray-darkest 45%, $color-primary 100%);
$mobilemenu-gradient: linear-gradient(135deg, $text-color 0%, $color-gray-darkest 45%, $color-primary 100%);
$mobilemenu-gradient: linear-gradient(135deg, $orange4 0%, $orange 45%, $color-primary 100%);

// $color-primary
// $color-gray-darkest
// $text-color