.skiplink:focus {
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 90;
    height: auto;
    padding: 0.625rem;
    width: auto;
    clip: unset;
    overflow: visible;
    white-space: normal;
    background: $color-secondary;
    color: $color-primary;
    outline: 1px solid $color-primary;
    outline-offset: -3px;
}

// .header-image__wrapper {
//     max-height: 45rem;
//     overflow: hidden;
//     // margin-bottom: 5rem;
//     img {
//         // background: url(images/bg.jpg) no-repeat center center fixed; 
//         -webkit-background-size: cover;
//         -moz-background-size: cover;
//         -o-background-size: cover;
//         background-size: cover;      
//         width: 100%;  
//     }
// }
