h1,
h2 {
    hyphens: auto;
    -webkit-hyphens: auto;
    line-height: 1;
    text-transform: uppercase;
    // margin: 0 0 1rem;
}
h1, h2, h3, h4 {
    color: $orange;
}

h1,
h2 {
    font-size: 2rem;
    font-weight: 700;

    @media screen and (max-width: 62em) {
        font-size: 1.6rem;
    }   
    @media screen and (max-width: 48em) {
        font-size: 1.4rem;
    }       
}

h2 {
    @media screen and (max-width: 62em) {
        font-size: 1.5rem;
    }   
    @media screen and (max-width: 48em) {
        font-size: 1.3rem;
    }       
}

// .subline {
//     font-size: .875rem;
//     font-weight: 700;

//     h1 + &,
//     h2 + & {
//         margin-top: -1rem;
//     }
// }

.button {
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border: 1px solid transparent;
    padding: 1.25rem 3.75rem;
}

.button--primary {
    background: $color-gray-darker;
    color: #fff;
}

.button--primary-light {
    border-color: $color-gray-darker;
    background: #fff;
    color: $color-gray-darker;
}

.button--secondary {
    background: transparent;
    color: $color-gray-darker;
}

@media screen and (min-width: 62em) {
    // h1,
    // h2 {
    //     // font-size: 4rem;
    // }

    .subline {
        font-size: 1.25rem;
    }
}