.grid {
    display: grid;
    grid-gap: 1.875rem;
}
.grid-gap-xl {
    grid-gap: 2.875rem;
}
.grid-gap-xs {
    grid-gap: .5rem;
}
.no-grid-gap {
    grid-gap: 0;
}

@media screen and (min-width: 48em) {
    .grid--two-columns-even {
        grid-template-columns: 1fr 1fr;
    }

    .grid--two-to-one {
        grid-template-columns: 2fr 1fr;
    }

    .grid--one-to-two {
        grid-template-columns: 1fr 2fr;
    }

    .grid--four-columns {
        grid-template-columns: 1fr 1fr;
    }    
}

@media screen and (min-width: 62em) {
    .grid--three-columns {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .grid--four-columns {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.grid-valign-middle {
    //This property is used at the grid container level. As already stated, the align deals with the vertical (block) axis.
    align-items: center;
}

// https://www.joomlashack.com/blog/tutorials/center-and-align-items-in-css-grid/


.grid-aktuelles {
    @media screen and (max-width: 62em) {
        grid-gap: 1.4rem;
        line-height: 1.2;
    }   
    @media screen and (max-width: 48em) {
        grid-gap: 1rem;
        line-height: 1;
    }   
    @media screen and (min-width: 62em) {
        // div:nth-child(2) {
        //     background: red;
        // }        
        // div:nth-child(3) {
        //     background: red;
        // }        
        // div:nth-child(4) {
        //     background: red;
        // }        
    }   
}