html.no-scroll {
    overflow: hidden;
}

body {
    background: #fff;
    background: #f9f3e9;
    color: $text-color;
}


.main-image {
    text-align: center;
    img {
        display: inline-block;
    }
}

a {
    color: $orange;
}

// tmp
button {
    line-height: 1;
    border: 0;
    padding: 0;
    background: transparent;
}
label,
button {
    cursor: pointer;
}
::placeholder {
    transition: opacity 0.3s;
}

// ::selection {
//     background: $color-primary;
//     color: #fff;
// }

input:focus {
    outline: 0;
    box-shadow: none;

    &::placeholder {
        opacity: 0;
    }
}
nav ul {
    font-size: 1rem;
    list-style: none;
    margin: 0;
    padding: 0;
}

// Button Styles
.button {
    font-size: 1rem;
    line-height: 1;
    text-decoration: none;
    display: inline-block;
    border: 1px solid;
    margin-top: 1rem;
    padding: 1rem;
    outline: 0;
    transition: color 0.3s, box-shadow 0.3s;

    &:hover,
    &:focus {
        text-decoration: none;
        transition: color 0.3s, box-shadow 0.3s;
    }
}

.button--primary {
    background-color: transparent;
    border-color: $text-color;
    color: $text-color;
    box-shadow: inset 0 0 0 $text-color;

    &:hover,
    &:focus {
        color: $text-color;
        // box-shadow: inset 0 0 12rem 2rem $textcolor;
    }
}

.button--secondary {
    border-color: #fff;
    color: #fff;
    box-shadow: inset 0 0 0 #fff;

    &:hover,
    &:focus {
        color: #fff;
        // box-shadow: inset 0 0 12rem 2rem #fff;
    }
}


.mt0 { margin-top: 0 !important; }
.mb0 { margin-bottom: 0 !important; }
.mt-1 { margin-top: 1rem; }
.mt-2 { margin-top: 3rem; }
.mt-3 { margin-top: 5rem; }
.mb-1 { margin-bottom: 1rem; }
.mb-2 { margin-bottom: 3rem; }
.mb-3 { margin-bottom: 5rem; }

// The less display-options, the less problems!
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}
.m-auto {
    margin: auto;
}

.partner {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}


.slick-img {
    width: 100%;
    margin:auto;
}

.btn {
    
}
.btn-link {
    text-decoration: underline;
}
.btn-white {
    color: #fff;
}



