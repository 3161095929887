.slick-prev:before
{
    content: none;
}
[dir='rtl'] .slick-prev:before
{
    content: none;
}

.slick-next:before
{
    content: none;
}
[dir='rtl'] .slick-next:before
{
    content: none;
}


.slick-arrow {
    .icon {
        width: 80px;
        height: 80px;
        stroke: black;
        fill: black;
    }

    &.slick-prev {
        left: -75px;
        width: 80px;
        height: 80px;
    }
    &.slick-next {
        right: -75px;
        width: 80px;
        height: 80px;
    }
}
