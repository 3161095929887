.image {
    margin: 0 auto;
}

@media screen and (min-width: 62em) {
    .image--half-width {
        max-width: 40rem;
    }
}

@media screen and (min-width: 90em) {
    .image--half-width {
        max-width: 50%;
    }
}

@media screen and (min-width: 120em) {
    .image--half-width {
        max-width: 80rem;
    }
}

.grid .image--half-width {
    max-width: 100%;
}