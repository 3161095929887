// Prevent changes of font-size after orientation changes in iOS.
html {
    -webkit-text-size-adjust: 100%;
}

// Remove the scrollbar when a fullscreen overlay is open (main navigation).
html.no-scroll {
    overflow: hidden;
}

/**
 * Normalize the box-sizing, remove horizontal overflow from the body
 * and remove default margin/padding in all browsers.
 */
body {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    box-sizing: border-box;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    background: #fff;
    color: $color-gray-darker;

    * {
        box-sizing: border-box;
    }
}

em,
i,
address {
    font-style: normal;
}

b,
strong {
    font-weight: 700;
}

// Set main and picture element to be a block level element in all browsers.
main,
picture {
    display: block;
}

// Normalize horizontal rules.
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    border-color: $color-gray-light;
}

// Buttons are weird in cross-browser country – let's change that.
button {
    font-family: inherit;
    font-size: inherit;
    line-height: 1;
    border: 0;
    padding: 0;
    background: transparent;

    > span {
        pointer-events: none;
    }

    &:focus {
        outline: 0;
    }
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

// Interactive elements get a pointer cursor because web marketing agencies decided so ten years ago.
label,
button {
    cursor: pointer;
}

/**
 * Set default focus style for all links (accessibility).
 * currentColor variable uses color of the link in its current state.
 * Make sure the link color has sufficient contrast to its background!
 */
a:focus,
button:focus {
    outline: .125rem dotted currentColor;
}

a:focus:not(:focus-visible),
button:focus:not(:focus-visible) {
    outline: 0;
}

a:focus-visible,
button:focus-visible {
    outline: .125rem dotted currentColor;
}

a.button,
button {
    outline-offset: .125rem;
}

// Let placeholder text fade out in Safari and chromium-based browsers.
::placeholder {
    opacity: 0.9;
    transition: opacity 0.3s;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;

    + fieldset {
        margin-top: 3rem;
    }
}

legend {
    font-weight: 700;
}

input:focus,
textarea:focus {
    outline: 0;
    box-shadow: none;

    &::placeholder {
        opacity: 0;
    }
}

/**
 * Set the line-height for a good reading experience, as most users re-read what they
 * wrote before sending forms.
 */
textarea {
    font-family: inherit;
    line-height: 1.5;
    overflow: auto;
}

// Prevent a webkit based bug with number inputs.
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

// Set selected text colors
::selection {
    background: $color-gray-darker;
    color: #fff;
}

// Normalize lists in navigation elements and remove defaults.
nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

// Remove browser default spacing from figure elements.
figure {
    margin: 0;
}

/**
 * Let images scale with the container they are in
 * and prevent a white bottom space from inline-block
 * default value for display property.
 */
img {
    display: block;
    height: auto;
    max-width: 100%;
}

// Span tables to 100% and remove spacing.
table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
}
