// Header Skiplink
.skiplink {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
}

.site-header {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 90;
    width: 100%;
    background-color: $orange;
    min-height: 3rem;
    max-width: 1920px;
    margin: auto;
    padding: 0;

    box-shadow: 0px 0px 8px 2px $orange;
}

.header__logo {
    // width: 118px;
    // height: 40px;
    // max-width: 165px;
}

@media screen and (min-width: 90em) {
    .header__logo {
        // max-width: 246px;
        // height: 83px;
        
    }
    .site-header {
        // position: absolute;
        // top: 0;
        // left: 0;
        // bottom: auto;
    }

    .main-navigation {
        .header-search {
            display: none;
        }
    }

    .main-navigation__toggle {
        display: none;
    }

    .main-navigation-wrapper {
        // align-items: flex-end;
        // margin: 0 auto;
        // padding: 1rem;
        // max-width: 102.5rem;
    }
    .main-navigation__dropdown {
    }
}










.main-navigation__list-item > a,
.main-navigation__list-item > div,
.main-navigation__desktop-meta > a {
    text-decoration: none;

    span {
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.main-navigation__dropdown {
    position: absolute;
    left: 0;
    top: calc(100%);
    visibility: hidden;
    height: 0;
    width: 100%;
    bottom: calc(100% + 1px);
    background: $orange;
    transform: scaleY(0);
    transform-origin: bottom;
    // transform-origin: top;
    transition: transform 0.3s;
    background: $mobilemenu-gradient;

    @media screen and (max-width: 89.9em) {
        &[aria-hidden="false"] {
            overflow-y: auto;
            visibility: visible;
            height: calc(100vh - 5rem); // Header Height is 110px = 6.875rem
            padding: 1rem 0;
            transform: scaleY(1);
    
            .main-navigation__list {
                a,
                button {
                    color: #fff;
                    font-weight: 700;
                    
                    &:hover {
                        color: #fff;
                    }
                }
    
                .main-navigation__dropdown-list-item {
                    a {
                        font-weight: normal;
                    }
                }            
            }
    
        }
    }
}

.main-navigation-wrapper {
    display: flex;
    align-items: center;
    margin: auto;
    max-width: 90rem;
    max-width: 75rem;

    .main-navigation {
        margin-left: auto;
    }
}

.main-navigation__empty-destination{
    cursor: default;

    padding: 1rem;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1;
}

.header__logo--link {
    padding: 1rem 1.25rem;
    padding: 0;

    display: inline-block;
    position: relative;
    // top: 20px;

    padding-left: 1rem;

    z-index: 999;

    max-width: 400px;

    // @media screen and (min-width: 60em) {
    //     padding-left: 0;
    // }
    @media screen and (max-width: 60em) {
        padding-left: 1rem;
    }

    // padding-left: 1rem;
}

.main-navigation__toggle {
    font-size: 3.5rem;
    padding: .7rem;
    // padding-top: 0;
    padding-bottom: 0;

    .icon {
        stroke: none;
        top: 0;
        fill: #fff
    }

    .icon--close {
        display: none;
    }

    &[aria-expanded="true"] {
        .icon--close {
            display: block;
        }

        .icon--open {
            display: none;
        }
    }

    &:hover,
    &:focus {
        color: #fff;
    }
}

.main-navigation__list {
    margin: 0 auto 1rem;
    max-width: 25rem;
}

.main-navigation__list-item:not(:first-child) {
    border-top: 1px solid #fff;
}

.main-navigation__dropdown-list-item .main-navigation__dropdown-toggle {
    padding: inherit;
    font-size: inherit;
    font-weight: 600;
}

.main-navigation__dropdown-toggle,
nav > ul > .main-navigation__desktop-dropdown-trigger,
.main-navigation__list-item > div,
.main-navigation__list-item > a {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1;
    color: $grey-light;
    display: block;
    padding: 1rem;
    width: 100%;

    &:hover,
    &:focus {
        text-decoration: none;
        outline: 0;
        color: $color-primary;
        color: #fff;
    }

    span {
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .icon {
        font-size: 1.25em;
        top: 0;
        color: #fff;
        transform-origin: center;
        transition: transform 0.3s, fill 0.3s;
    }

    &[aria-expanded="true"] {
        .icon {
            transform: rotate(180deg);
        }
    }
}

.main-navigation__list-item {
    .main-navigation__desktop-dropdown-trigger {
        display: none;
    }
}

.main-navigation__list-item > a[aria-current],
.main-navigation__list-item > a[aria-current]:hover,
.main-navigation__list-item > a[aria-current]:focus {
    color: $color-primary;
}

.main-navigation__list > li > .main-navigation__dropdown-list {
    width: 100%;
    transform: scaleY(0);
    transform-origin: bottom;

    &[aria-hidden="false"],
    &:hover {
        padding: 0 2rem 2rem;
        transform: scaleY(1);
    }

    li + li {
        margin-top: 0.5rem;
    }
}

.main-navigation__dropdown-list {
    visibility: hidden;
    height: 0;

    &[aria-hidden="false"],
    &:hover {
        visibility: visible;
        height: auto;
    }

    a {
        color: $grey-light;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $color-primary;
        }

        &[aria-current] {
            color: $color-primary;

            &:hover,
            &:focus {
                font-weight: 700;
                color: $color-primary;
            }
        }
    }

    .main-navigation__dropdown-list {
        margin-left: 1em;
        max-height: 0;
        opacity: 0;
        transition: 0.8s;
    }

    button ~ .main-navigation__dropdown-list[aria-hidden="false"],
    .main-navigation__dropdown-list:hover,
    .main-navigation__desktop-dropdown-trigger:hover + .main-navigation__dropdown-list {
        visibility: visible;
        max-height: 100vh;
        height: auto;
        opacity: inherit;
        transform-origin: top;
        transition: 0.8s;
    }
}

.main-navigation__desktop-meta {
    display: none;
}

@media screen and (min-width: 90em) {
    .main-navigation__dropdown-list {
        height: auto;

        .main-navigation__dropdown-list {
            position: absolute;
            left: 100%;
            background-color: $color-primary;
            padding: 1rem;
            margin-left: 1px;
            margin-top: -3rem;
            min-width: 100%;
        }
    }

    .main-navigation {
        margin-left: auto;
    }

    .main-navigation__dropdown {
        position: static;
        visibility: visible;
        height: auto;
        background: transparent;
        transform: scaleY(1);
    }

    .header-search,
    .main-navigation-swimmer {
        display: none;
    }

    .main-navigation__list {
        display: flex;
        align-items: flex-end;
        margin: 0 auto;
        max-width: none;
    }

    .main-navigation__list-item {
        position: relative;

        &:not(:first-child) {
            border-top: 0;
        }
    }

    .main-navigation__list-item .main-navigation__desktop-dropdown-trigger,
    .main-navigation__list-item > div,
    .main-navigation__list-item > a {
        display: block;
        position: relative;
        width: auto;
        color: $text-color;
        color: #fff;

        .icon {
            font-size: 1rem;
            margin-left: .25rem;
            color: #fff;
        }
    }

    .main-navigation__desktop-dropdown-trigger:hover {
        text-decoration: none;
    }

    .main-navigation__list-item:hover {
        > .main-navigation__desktop-dropdown-trigger {
            color: $color-primary;

            .icon {
                transform: rotate(180deg);
            }
        }

        > .main-navigation__dropdown-list {
            z-index: 90;
            visibility: visible;
            height: auto;
            padding: 1rem;
            transform: scaleY(1);
        }
    }

    .main-navigation__dropdown-toggle {
        position: absolute;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
    }

    .main-navigation__list > li > .main-navigation__dropdown-list {
        position: absolute;
        left: 0;
        top: 99%;
        min-width: 16rem;
        background: #fff;
        transform-origin: top;
        transition: top .3s, transform .3s;

        &[aria-hidden="false"],
        &:hover {
            padding: 1rem;
        }

        a {
            color: $text-color;

            &:hover,
            &:focus {
                text-decoration: none;
                color: $color-primary;
            }

            &[aria-current] {
                color: $text-color;

                &:hover,
                &:focus {
                    color: $color-primary;
                }
            }
        }
    }

    .main-navigation__dropdown-list-item--first {
        display: none;
    }
}

.main-navigation__mobile-top-nav {
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    align-items: center;

    @media screen and (min-width: 90em) {
        display: none;
    }

    .main-navigation__mobile__item:first-child {
        background-color: $orange1;
    } 
}

.main-navigation__mobillist-item {
    @media screen and (min-width: 90em) {
        display: none;
    }    
}